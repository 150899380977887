<template>
	<div class="app-body">
		<div class="a-flex-rsbc a-ptb-6">
			<span class="a-fs-16 a-fw-b">工单列表</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="editWorkOrder">创建工单</el-button>
                <!-- <el-button icon="el-icon-upload2" class="s-btn-exp">导出</el-button> -->
            </div>
		</div>
		<el-card class="el-main">
            <div class="a-flex-rfsc">
                <div class="a-flex-ccc complaint-item a-c-white" :style="statusStyle[item.value]" v-for="(item,index) in statusDic" :key="index" @click="handleClickCount(item.value)">
                    <span class="title" :style="statusColorStyle[item.value]">{{ item.label }}</span>
                    <span class="waitNum" :style="statusColorStyle[item.value]">{{ pageCount[item.value] }}</span>
                </div>
            </div>
			<le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
				<le-input label="工单标题" v-model="pageParam.params.title" placeholder="请输入内容" />
                <le-select-local-search label="发布类型" v-model="pageParam.params.sendType" :options="sendTypeDic" />
                <le-select-local-search label="状态" multiple v-model="pageParam.params.status" :options="statusDic" />
                <le-select-local-search label="重要程度" v-model="pageParam.params.importanceLevel" :options="importanceLevelDic" />
                <le-input label="工单编号" v-model="pageParam.params.sn" placeholder="请输入内容" />
                <le-select-local-search label="处理类型" v-model="pageParam.params.solveType" :options="solveTypeDic" />
                <le-date-range 
                    label="发布时间" 
                    :minDate.sync="pageParam.params.sendTimeStart" 
                    :maxDate.sync="pageParam.params.sendTimeEnd" 
                    :pickerOptions='pickerOptions' 
                    :defaultTime="['00:00:00','23:59:59']"
                    valueFormat="yyyy-MM-dd HH:mm:ss" />
                <le-select-remote-search 
                    v-model="pageParam.params.solveRbacUser" 
                    label="执行人"
                    :options="optionsSolveRbacUser" 
                    placeholder="请选择执行人（可输入搜索）" />
                <le-select-remote-search 
                    v-model="pageParam.params.stationId" 
                    label="站点"
                    :options="optionsStation" 
                    placeholder="请选择执行人（可输入搜索）" />
                <le-input label="审批编号" v-model="pageParam.params.auditSn" placeholder="请输入内容" />
                <le-date-range 
                    label="截止时间" 
                    :minDate.sync="pageParam.params.endTimeStart" 
                    :maxDate.sync="pageParam.params.endTimeEnd" 
                    :pickerOptions='pickerOptions'
                    :defaultTime="['00:00:00','23:59:59']"
                    valueFormat="yyyy-MM-dd HH:mm:ss" />
                <le-input-district-choose 
                    label="省市区" 
                    :province.sync="pageParam.params.provinceCode" 
                    :city.sync="pageParam.params.cityCode" 
                    :area.sync="pageParam.params.districtCode">
                </le-input-district-choose>
			</le-search-form>
			<le-pagview ref="workOrderPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.workOrderList">
                <template slot="headerLabel" slot-scope="scope" >
                    <div class="a-flex-rfsc">
                        <span class="a-fs-14 a-c-master a-fw-700 ">已选择：</span>
                        <!-- <el-button size='mini' type="primary" @click="handleDeleteSelectAll">一键清除</el-button> -->
                        <el-dropdown placement="bottom-start" @command="handlerOperationBatch">
                            <el-button size='mini' type="primary" class="a-mlr-12">批量操作</el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item :command='"gq"'>挂起</el-dropdown-item>
                                <el-dropdown-item :command='"jcgq"'>解除挂起</el-dropdown-item>
                                <el-dropdown-item :command='"bgzxr"'>变更执行人</el-dropdown-item>
                                <el-dropdown-item :command='"yq"'>延期</el-dropdown-item>
                                <!-- <el-dropdown-item :command='"tjms"'>添加描述</el-dropdown-item> -->
                                <el-dropdown-item :command='"zycd"'>重要程度</el-dropdown-item>
                                <!-- <el-dropdown-item :command='"wcgd"'>完成工单</el-dropdown-item> -->
                            </el-dropdown-menu>
                        </el-dropdown>
                        <el-button size='mini' type="primary" plain @click="handleDeleteCancelAll">取消批量</el-button>
                        <el-button size='mini' type="primary" @click="handleConfirmAll">确认选择</el-button>
                    </div>
                    <div class="checkedDevList a-flex-wrap a-flex-rfsc a-mt-6" v-show="selectData.length">
                        <el-tag
                            class="a-mr-08 a-mb-05"
                            v-for="tag in selectData"
                            :key="tag.sn"
                            @close="handleDeleteSelect(tag)"
                            closable>
                            {{tag.sn}}
                        </el-tag>
                    </div>
                </template>
				<el-table 
                    ref="workOrderList" 
                    :data="tableData" 
                    :row-key="getRowKey"
                    @selection-change="handleSelectionChange"
                    :highlight-current-row="true" 
                    style="width: 100%" 
                    v-sticky="{ top: 0, parent:'.el-card__body' }">
                    <el-table-column type="selection" :selectable='handleSelectable' fixed="left" align="center" width="55" reserve-selection  class="device-checkBox"></el-table-column>
					<el-table-column prop="title" label="工单标题" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.title || "-" }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="sn" label="工单编号" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.sn || "-" }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="errorType" label="工单类型" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.errorType | initDic(errorTypeDic) }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="status" label="状态" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.status | initDic(statusDic) }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="solveType" label="处理类型" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.solveType | initDic(solveTypeDic) }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="sendType" label="发布类型" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.sendType | initDic(sendTypeDic) }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="stationName" label="站点" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.stationName || "-" }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="stationId" label="站点ID" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.stationId || "-" }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="stationType" label="站点类型" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.stationType | initDic(stationTypeDic) }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="address" label="地址" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.address || "-" }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="auditSn" label="审批编号" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.auditSn || "-" }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="sendTime" label="发布时间" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.sendTime || "-" }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="sendName" label="发布人" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.sendName || "-" }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="endTime" label="截止时间" min-width="120">
                        <template slot-scope="{ row }">
							<span :style="setStatusStyle(row)">{{ row.endTime || "-" }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="solveName" label="执行人" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.solveName || "-" }}-{{ row.solveMobile || '-' }}</span>
						</template>
					</el-table-column>
                    
                    <el-table-column prop="importanceLevel" label="重要程度" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.importanceLevel | initDic(importanceLevelDic) }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="patrolSn" label="关联巡检" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.patrolSn || "-" }}</span>
						</template>
					</el-table-column>

					<el-table-column label="操作" width="80" fixed="right">
						<template slot-scope="scope">
							<el-tooltip class="item" effect="dark" content="工单详情">
								<img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="handleToInfo(scope.row)" />
							</el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="更多操作" placement="top">
                                <el-dropdown placement="bottom-start" @command="handlerOperation">
                                    <img src="../../assets/icon/more-operation.png" class="a-wh-16" />
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item 
                                            :command='"gq"+"#"+scope.$index' 
                                            :disabled="!((scope.row.status == 1 || scope.row.status == 2 || scope.row.status == 4 || scope.row.status == 6)
                                            && (userInfo.dataPermission == 99 || userInfo.dataPermission == 88))"
                                            >
                                            挂起
                                        </el-dropdown-item>
                                        <el-dropdown-item :command='"jcgq"+"#"+scope.$index' 
                                            :disabled="!(scope.row.status == 7 && (userInfo.dataPermission == 99 || userInfo.dataPermission == 88))"
                                            >解除挂起</el-dropdown-item>
                                        <el-dropdown-item :command='"bgzxr"+"#"+scope.$index'
                                            :disabled="!((scope.row.status != 3 && scope.row.status != 5)
                                            && (userInfo.dataPermission == 99 || userInfo.dataPermission == 88))"
                                            >变更执行人</el-dropdown-item>
                                        <el-dropdown-item :command='"yq"+"#"+scope.$index'
                                            :disabled="!(scope.row.status != 3 && scope.row.status != 5 && (userInfo.dataPermission == 99 || userInfo.dataPermission == 88))"
                                            >延期</el-dropdown-item>
                                        <el-dropdown-item :command='"tjms"+"#"+scope.$index' :disabled="!(scope.row.status != 3 && scope.row.status != 5)">添加描述</el-dropdown-item>
                                        <el-dropdown-item 
                                            :command='"zycd"+"#"+scope.$index' 
                                            :disabled="!((scope.row.status != 3 && scope.row.status != 5)
                                            && (userInfo.dataPermission == 99 || userInfo.dataPermission == 88))">重要程度</el-dropdown-item>
                                        <el-dropdown-item 
                                            :command='"wcgd"+"#"+scope.$index' 
                                            :disabled="!(scope.row.status != 3 && scope.row.status != 5 && scope.row.status != 7 && userInfo.dataPermission == 99)">完成工单</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </el-tooltip>
						</template>
					</el-table-column>
				</el-table>
			</le-pagview>
		</el-card>
        <change-executor ref="changeExecutor" @handlerSuccess='handlerChangeExecutorSuccess' :tableDataItem='currentDatas' type='workOrder'></change-executor>
        <change-deadline ref="changeDeadline" @handlerSuccess='handlerChangeDeadlineSuccess' :tableDataItem='currentDatas'></change-deadline>
        <add-desc ref="addDesc" @handlerSuccess='handlerAddDescSuccess' :tableDataItem='currentDatas'></add-desc>
        <change-import-level ref="importLevel" @handlerSuccess='handlerChangeImportantSuccess' :tableDataItem='currentDatas'></change-import-level>
        <batch-error ref="batchError" :tableData="batchErrorList" @handlerReTry='handlerReTry'></batch-error>
	</div>
</template>

<script>
let _this
import util from "../../utils/util.js";
import AddDesc from './child/add-desc.vue';
import ChangeDeadline from './child/change-deadline.vue';
import changeExecutor from './child/change-executor.vue';
import ChangeImportLevel from './child/change-importLevel.vue';
import { mapState } from 'vuex';
import batchError from './child/batch-error.vue';
export default {
    components: { changeExecutor, ChangeDeadline, AddDesc, ChangeImportLevel, batchError },
	data() {
		return {
			util: util,
			tableData: [],
			pageParam: {
				url: this.$Config.apiUrl.workOrderPage,
				method: "post",
				params: {
                    provinceCode: "",
                    cityCode: "",
					districtCode: "",
					title: '',
					sendType: '',
					status: [],
					importanceLevel: '',
					sn: '',
					solveType: '',
					sendTimeStart: '',
					sendTimeEnd: '',
					solveRbacUser: '',
					auditSn: '',
					endTimeStart: '',
					endTimeEnd: '',
                    stationId: ''
				},
                freshCtrl: 1,
			},
            importanceLevelDic: [],
            errorTypeDic: [],
            solveTypeDic: [],
            sendTypeDic: [],
            stationTypeDic: [],
            statusDic: [],
            optionsSolveRbacUser: {
                url: this.$Config.apiUrl.getExecutor,
                method: "post",
                params: {
                },
                showLoading: false,
                labelKey: "userName",
                otherLabelKey: "companyName",
                valueKey: "id",
                searchKey: "name", 
                pageSize: 200
            },
            optionsStation: {
                url: this.$Config.apiUrl.getStationInfoList,
                method: "post",
                params: {
                    incomeId: ''
                },
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "searchKey",  //incomeDistriId
                pageSize: 200
            },//站点数据
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(_this.$getDay.getTodayLastTime('timeStamp') - 3600 * 1000 * 24 * 6);
                        end.setTime(_this.$getDay.getTodayLastTime('timeStamp'));
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(_this.$getDay.getTodayLastTime('timeStamp') - 3600 * 1000 * 24 * 30);
                        end.setTime(_this.$getDay.getTodayLastTime('timeStamp'));
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(_this.$getDay.getTodayLastTime('timeStamp') - 3600 * 1000 * 24 * 91);
                        end.setTime(_this.$getDay.getTodayLastTime('timeStamp'));
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            currentDatas: '',//当前操作的列表项
            currentBatch: '',
            selectData: [],
            batchErrorList: [],
            pageCount: {},
            statusStyle: {
                1: 'background: #E6A23C40',
                2: 'background: #007af040',
                3: 'background: #67C23A40',
                4: 'background: #F56C6C40',
                5: 'background: #FF484840',
                6: 'background: #24d4d040',
                7: 'background: #66666640',
            },
            statusColorStyle: {
                1: 'color: #E6A23C',
                2: 'color: #007af0',
                3: 'color: #67C23A',
                4: 'color: #F56C6C',
                5: 'color: #FF4848',
                6: 'color: #24d4d0',
                7: 'color: #666666',
            }
		}
	},
    computed:{
        ...mapState({
            company: state => state.company.company,
            userInfo: state => state.user.user,
        })
    },
    created () {
        _this = this
        this.$getDic('importLevel','select').then(res=>{ this.importanceLevelDic = res; })
        this.$getDic('workOrderSolveType','select').then(res=>{ this.solveTypeDic = res; })
        this.$getDic('workOrderSendType','select').then(res=>{ this.sendTypeDic = res; })
        this.$getDic('stationType','select').then(res=>{ this.stationTypeDic = res; })
        this.$getDic('workOrderStatus','select').then(res=>{ this.statusDic = res; })
        this.getErrorTypeDic()
    },
    activated () {
        this.pageParam.freshCtrl++;
        this.getWorkOrderState()
    },  
	mounted() { },
	methods: {
		setTableData(data) {
			this.tableData = data;
		},
        handlerRest() {
			this.pageParam.params = {
                provinceCode: "",
                cityCode: "",
                districtCode: "",
				title: '',
                sendType: '',
                status: [],
                importanceLevel: '',
                sn: '',
                solveType: '',
                sendTimeStart: '',
                sendTimeEnd: '',
                solveRbacUser: '',
                auditSn: '',
                endTimeStart: '',
                endTimeEnd: '',
                stationId: ''
			};
			this.handlerSearch()
		},
		handlerSearch() {
			this.$refs['workOrderPage'].pageNum = 1
			this.pageParam.freshCtrl++;
            this.getWorkOrderState()
		},
        getRowKey (row) {
            return row.id
        },
        handleSelectionChange (selection) {
            this.selectData = selection
        },
        handleDeleteSelect (datas) {
            this.selectData.splice(this.selectData.indexOf(datas), 1);
            this.$refs['workOrderList'].toggleRowSelection(datas)
        },
        handleDeleteSelectAll () {
            this.$refs['workOrderList'].clearSelection()
            this.selectData = []
        },
        handlerReTry () {
            this.handleConfirmAll()
        },
        handleConfirmAll () {
            if(!this.selectData.length) {
                this.$message.error('请至少选择一项数据')
                return false
            }
            let reqData
            switch (this.currentBatch) {
                case 'gq':
                    // 挂起
                    this.$prompt('请输入备注', '是否确认挂起？', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                    }).then(({ value })=>{
                        try {
                            let ids = this.selectData.map(item=>{
                            return item.id
                        })
                        reqData = {
                            ids: ids,
                            operateType: 5, 
                            remark: value
                        }
                        this.handleSubmitOperate(reqData)
                        } catch (error) {
                            console.log(error);
                        }
                    }).catch(_=>{

                    })
                    break;
                case 'jcgq':
                    // 解除挂起
                    this.$prompt('请输入备注', '是否确认解除挂起？', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                    }).then(({ value })=>{
                        let ids = this.selectData.map(item=>{
                            return item.id
                        })
                        reqData = {
                            ids: ids,
                            operateType: 6, 
                            remark: value
                        }
                        this.handleSubmitOperate(reqData)
                    }).catch(_=>{})
                    break;
                case 'bgzxr':
                    // 变更执行人
                    this.$refs['changeExecutor'].dialogVisible = true
                    break;
                case 'yq':
                    // 延期
                    this.$refs['changeDeadline'].dialogVisible = true
                    break;
                case 'tjms':
                    // 添加描述
                    this.$nextTick(()=>{
                        this.$refs['addDesc'].getDescList()
                        this.$refs['addDesc'].dialogVisible = true
                    })
                    break;
                case 'zycd':
                    // 修改重要程度
                    this.$refs['importLevel'].dialogVisible = true
                    break;
                default:
                    break;
            }
        },
        // 修改执行人
        handlerChangeExecutorSuccess (datas) {
            let ids = this.selectData.map(item=>{
                return item.id
            })
            let reqData = {
                id: this.currentDatas ? this.currentDatas.id : '',
                ids: ids,
                ...datas,
                operateType: 3, 
            }
            this.handleSubmitOperate(reqData)
        },
        // 修改截止时间
        handlerChangeDeadlineSuccess (datas) {
            let ids = this.selectData.map(item=>{
                return item.id
            })
            let reqData = {
                id: this.currentDatas ? this.currentDatas.id : '',
                ids: ids,
                ...datas,
                operateType: 7, 
            }
            this.handleSubmitOperate(reqData)
        },
        // 添加描述
        handlerAddDescSuccess (datas) {
            let ids = this.selectData.map(item=>{
                return item.id
            })
            let reqData = {
                id: this.currentDatas ? this.currentDatas.id : '',
                ids: ids,
                operateType: 2, 
                ...datas
            }
            this.handleSubmitOperate(reqData)
        },
        handlerChangeImportantSuccess (datas) {
            let ids = this.selectData.map(item=>{
                return item.id
            })
            let reqData = {
                id: this.currentDatas ? this.currentDatas.id : '',
                ids: ids,
                ...datas,
                operateType: 10, 
            }
            this.handleSubmitOperate(reqData)
        },
        getErrorTypeDic () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getWorkOrderErrorType,
                method: "post",
                params: {},
            })
            .then(({ data })=>{
                this.errorTypeDic = data.map(item=>{
                    return {
                        label: item.name,
                        value: item.id
                    }
                })
            })
            .catch(err=>{

            })
        },
        getWorkOrderState () {
            this.$Axios._post({
                url: this.$Config.apiUrl.workOrderPageCount,
                method: "post",
                params: {
                    ...this.pageParam.params
                },
            })
            .then(({ data })=>{
                this.pageCount = data.reduce((acc, {status, count}) => {
                    acc[status] = count;
                    return acc;
                }, {});
            })
            .catch(err=>{

            })
		},
        // 创建工单
        editWorkOrder () {
            this.$router.push({
                path:'/workOrder/workOrder-edit',
            })
        },
        handleToInfo (datas) {
            this.$router.push({
                path:'/workOrder/workOrder-info',
                query: {
                    id: datas.id?datas.id:''
                }
            })
        },
        // 更多操作
        handlerOperation (commands) {
            let command = commands.split('#')[0]
            let index = commands.split('#')[1]
            let reqData
            switch (command) {
                case 'gq':
                    // 挂起
                    this.$prompt('请输入备注', '是否确认挂起？', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                    }).then(({ value })=>{
                        reqData = {
                            id: this.tableData[index].id,
                            operateType: 5, 
                            remark: value
                        }
                        this.handleSubmitOperate(reqData)
                    }).catch(_=>{})
                    break;
                case 'jcgq':
                    // 解除挂起
                    this.$prompt('请输入备注', '是否确认解除挂起？', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                    }).then(({ value })=>{
                        reqData = {
                            id: this.tableData[index].id,
                            operateType: 6, 
                            remark: value
                        }
                        this.handleSubmitOperate(reqData)
                    }).catch(_=>{})
                    break;
                case 'bgzxr':
                    // 变更执行人
                    this.currentDatas = this.tableData[index]
                    this.$refs['changeExecutor'].dialogVisible = true
                    break;
                case 'yq':
                    // 延期
                    this.currentDatas = this.tableData[index]
                    this.$refs['changeDeadline'].dialogVisible = true
                    break;
                case 'tjms':
                    // 添加描述
                    this.currentDatas = this.tableData[index]
                    this.$nextTick(()=>{
                        this.$refs['addDesc'].getDescList()
                        this.$refs['addDesc'].dialogVisible = true
                    })
                    break;
                case 'zycd':
                    // 修改重要程度
                    this.currentDatas = this.tableData[index]
                    this.$refs['importLevel'].dialogVisible = true
                    break;
                case 'wcgd':
                    // 直接完成
                    this.currentDatas = this.tableData[index]
                    this.$confirm('是否确认直接完成工单?', '温馨提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(_=>{
                        this.$Axios._post({
                            url: this.$Config.apiUrl.getWorkOrderDetail,
                            method: "post",
                            params: {
                                id: this.currentDatas.id
                            },
                        })
                        .then(({data})=>{
                            let reqData = {
                                commit: 1,
                                solveType: data.solveType,
                                auditSn:data.auditSn,
                                content: data.content,
                                images: data.images,
                                solveContent: data.solveContent,
                                solveImages: data.solveImages,
                                workOrderId: this.currentDatas.id,
                                newDeviceIds: data.newDeviceIds,
                                oldDeviceIds: data.oldDeviceIds
                            }
                            this.commitWorkOrder(reqData)
                        })
                        .catch(err=>{

                        })
                    }).catch(err=>{
                        console.log(err);
                    })
                    break;
                default:
                    break;
            }
        },
        handlerOperationBatch (commands) {
            this.currentBatch = commands
        },
        handleDeleteCancelAll () {
            this.currentBatch = ""
            this.handleDeleteSelectAll()
        },
        handleSelectable (row,index) {
            let isSelect = false
            switch (this.currentBatch) {
                case 'gq':
                    // 挂起
                    isSelect = ((row.status == 1 || row.status == 2 || row.status == 4 || row.status == 6) && (this.userInfo.dataPermission == 99 || this.userInfo.dataPermission == 88))
                    break;
                case 'jcgq':
                    // 解除挂起
                    isSelect = (row.status == 7 && (this.userInfo.dataPermission == 99 || this.userInfo.dataPermission == 88))
                    break;
                case 'bgzxr':
                    // 变更执行人
                    isSelect = ((row.status != 3 && row.status != 5) && (this.userInfo.dataPermission == 99 || this.userInfo.dataPermission == 88))
                    break;
                case 'yq':
                    // 延期
                    isSelect = (row.status != 3 && row.status != 5 && (this.userInfo.dataPermission == 99 || this.userInfo.dataPermission == 88))
                    break;
                case 'tjms':
                    // 添加描述
                    isSelect = (row.status != 3 && row.status != 5)
                    break;
                case 'zycd':
                    // 修改重要程度
                    isSelect = ((row.status != 3 && row.status != 5) && (this.userInfo.dataPermission == 99 || this.userInfo.dataPermission == 88))
                    break;
                case 'wcgd':
                    // 直接完成
                    isSelect = (row.status != 3 && row.status != 5 && row.status != 7 && this.userInfo.dataPermission == 99)
                    break;
                default:
                    break;
            }
            return isSelect
        },
        commitWorkOrder (reqData) {
            this.$Axios._post({
                url: this.$Config.apiUrl.commitWorkOrder,
                method: "post",
                params: {
                    ...reqData
                },
            })
            .then((res)=>{
                this.currentDatas = ''
                this.pageParam.freshCtrl++;
            })
            .catch(err=>{

            })
        },
        handleSubmitOperate (reqData) {
            this.$Axios._post({
                url: this.$Config.apiUrl.operateWorkOrder,
                method: "post",
                params: {
                    ...reqData
                },
            })
            .then(({data})=>{
                this.$message.success('操作成功')
                this.currentDatas = ''
                this.pageParam.freshCtrl++;
                if(data.length) {
                    this.batchErrorList = data
                    let arr = []
                    this.$refs['batchError'].dialogVisible = true
                    this.selectData.map(item=>{
                        let targetIndex = this.batchErrorList.findIndex(ite=>{
                            return ite.id == item.id
                        })
                        if(targetIndex != -1) {
                            arr.push(item)
                        }else {
                            this.$refs['workOrderList'].toggleRowSelection(item)
                        }
                    })
                    this.selectData = arr
                }else {
                    this.handleDeleteCancelAll()
                }
            })
            .catch(err=>{
                
            })
        },
        handleClickCount (code) {
            if(this.pageParam.params.status.indexOf(code) == -1) {
                this.pageParam.params.status.push(code)
            }else {
                this.pageParam.params.status.splice(this.pageParam.params.status.indexOf(code),1)
            }
            this.handlerSearch()
        },
        setStatusStyle (datas) {
            let currentTime = Date.now()
            let deadLineTime = this.$getDay.getTimeUnix(datas.endTime)
            let timeDiff = deadLineTime - currentTime
            if(timeDiff > 24 * 60 * 60 * 1000) {
                return ''
            }else if(timeDiff < 24 * 60 * 60 * 1000 && timeDiff > 0) {
                return 'color: #F56C6C'
            }else if(timeDiff <= 0) {
                return 'color: #E6A23C'
            }
        }
	},
};
</script>

<style lang="scss" scoped>
    .s-btn-add{
        width: 105px;
    }
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        /*width: 0;宽度为0隐藏*/
        width: 0 !important;
        height: 0 !important;
    }
    /deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
        height: 100% !important;
    }
    .complaint-item{
        margin-right: 20px;
        width: 100px;
        height: 48px;
        border-radius: 8px;

        .title{
            font-size: 14px;
            font-weight: 500;
        }
        .waitNum{
            font-size: 16px;
            font-weight: 700;
            // color: #007AFF;
        }
        .waitText{
            font-size: 16px;
            font-weight: 400;
            // color: #007AFF;
        }
        .overtimeText{
            font-size: 14px;
            color: #666666;
        }
        .overtimeNum{
            font-size: 20px;
            color: #F56C6C;
        }
    }
</style>
